import { breakpoint } from 'src/shared/styles/variables';
import styled from 'styled-components';

export const WrapperPqrs = styled.div`
    width: 100%;
    padding: 20px 17px;

    ${breakpoint('md')({
        padding: '50px 34px'
    })}

    ${breakpoint('lg')({
        padding: '50px 105px'
    })}
`;
export const WrapperTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 0 25px;
    max-width: 995px;
    border-bottom: 0.5px solid #c5c3c9;
    margin: auto;
    margin-right: 10px;
    margin-left: 10px;

    img {
        display: none;
    }

    img {
        ${breakpoint('lg')({
            display: 'block',
            width: '30px',
            paddingBottom: '14px'
        })}
    }

    ${breakpoint('lg')({
        marginRight: 'auto',
        marginLeft: 'auto'
    })}
`;

export const ContainerMainImage = styled.div`
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-evenly;
    align-items: center;
    padding: 40px 10px 20px 10px;

    img {
        display: none;
    }

    img {
        ${breakpoint('md')({
            display: 'block',
            width: '298px'
        })}
    }

    img {
        ${breakpoint('lg')({
            display: 'block',
            width: 'auto'
        })}
    }

    ${breakpoint('md')`
        padding: 40px 20px;
    `}
`;

export const TextContainer = styled.div`
    b {
        font-family: ${(props) => props.theme.fonts.mon_bold};
        font-size: 16px;

        ${breakpoint('md')({
            fontSize: '20px',
            lineHeight: '24px'
        })}

        ${breakpoint('lg')({
            fontSize: '28px',
            lineHeight: '33.6px'
        })}
    }

    p:first-child {
        margin-bottom: 7px;

        ${breakpoint('md')({
            marginBottom: '12px'
        })}

        ${breakpoint('lg')({
            marginBottom: '12px'
        })}
    }

    p {
        font-family: ${(props) => props.theme.fonts.mon_regular};
        font-size: 14px;
        line-height: 16.8px;

        & > i {
            color: ${(props) => props.theme.colors.accents.base.yellow};
            font-family: ${(props) => props.theme.fonts.mon_bold};
            font-size: 16px;
            line-height: 19.2px;

            ${breakpoint('md')({
                fontSize: '20px',
                lineHeight: '24px'
            })}

            ${breakpoint('lg')({
                fontSize: '28px',
                lineHeight: '30.6px'
            })}
        }

        ${breakpoint('md')({
            fontSize: '14px',
            lineHeight: '16px'
        })}

        ${breakpoint('lg')({
            fontSize: '16px',
            lineHeight: '19.2px'
        })}
    }
    ${breakpoint('md')({
        width: '269px'
    })}

    ${breakpoint('lg')({
        width: '378px'
    })}
`;

export const Title = styled.h1`
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 18px;
    line-height: 21.6px;
    color: ${(props) => props.theme.colors.accents.base.yellow};
    text-align: center;

    ${breakpoint('lg')({
        fontSize: '28px',
        lineHeight: '33.6px'
    })}
`;

export const WrapperBody = styled.div`
    width: 100%;
`;
