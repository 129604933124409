import React, { useEffect } from 'react';
import { Breadcrumbs, ButtonGoBack, Layout, SEO } from '@componentsShared';
import {
    ContainerMainImage,
    TextContainer,
    Title,
    WrapperBody,
    WrapperPqrs,
    WrapperTop
} from './styled';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Viewport from 'src/shared/hooks/viewport';
import { ContainerCase, ContainerPqrs } from '@components';
import { graphql, useStaticQuery } from 'gatsby';
import { getMetadataPage } from '@utils/metadata';

const PQRS = () => {
    const viewport = Viewport();

    const dataPqrs = useStaticQuery(graphql`
        query queryPqrs {
            allContentfulPaginaInternaFooter(filter: { page: { eq: "Contactanos" } }) {
                nodes {
                    title
                    slug
                    imageText {
                        mediaFile {
                            file {
                                url
                            }
                            description
                        }
                        text {
                            raw
                        }
                    }
                    pageIcon {
                        file {
                            url
                        }
                        description
                    }
                    secondaryImage {
                        file {
                            url
                        }
                        description
                    }
                    typesRequestPqrs {
                        question
                        answer {
                          raw
                        }
                    }
                    metaData {
                        title
                        descripcion
                        keyWords
                        image {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const {
        allContentfulPaginaInternaFooter: {
            nodes: [{ title, imageText, pageIcon, secondaryImage, secondarySection, typesRequestPqrs, metaData, slug }]
        }
    } = dataPqrs;

    let crumbs = [
        {
            crumbLabel: 'Inicio',
            pathname: '/'
        },
        {
            crumbLabel: 'Contáctanos',
            pathname: 'contactanos/'
        }
    ];

    return (
        <Layout>
            <SEO {...getMetadataPage(metaData, slug)} />

            <WrapperPqrs>
                {viewport.desktop ? <Breadcrumbs crumbs={crumbs} /> : <ButtonGoBack />}

                <WrapperTop>
                    <img src={pageIcon.file.url} alt={pageIcon.description} />
                    <Title>{title}</Title>
                </WrapperTop>

                <ContainerMainImage>
                    <img src={imageText[0].mediaFile.file.url} alt={imageText[0].mediaFile.description} />
                    <TextContainer>
                        {documentToReactComponents(JSON.parse(imageText[0].text.raw))}
                    </TextContainer>
                </ContainerMainImage>

                <WrapperBody>
                    <ContainerPqrs auxImage={secondaryImage} typesRequest={typesRequestPqrs} />
                    {/* <ContainerCase /> */}
                </WrapperBody>
            </WrapperPqrs>
        </Layout>
    );
};

export default PQRS;
